import React, { Component, useState, useEffect } from "react";
import { FlatList } from "react-native-gesture-handler";
var _ = require("lodash");
import {
  ScrollView,
  Switch,
  StyleSheet,
  Text,
  View,
  Image,
  SectionList,
} from "react-native";
import Axios from "axios";
import { Headline } from "react-native-paper";

const MenuListOnOutlet = ({ route, navigation }) => {
  const [MenuDetail, setMenuDetail] = useState([]);
  const [OutletDesc, setOutletDesc] = useState([]);
  setOutletDesc;
  const { tId } = route.params;
  useEffect(() => {
    Axios.get(
      `https://shelldanlabs.com:3010/api/getMenuOnOutlet/${JSON.stringify(tId)}`
    ).then((responce) => {
      // setMenuDetail(responce.data);

      const groupedArray = _.groupBy(responce.data, "cat_name");

      let result = [];
      for (const [key, value] of Object.entries(groupedArray)) {
        result.push({
          title: key,
          data: value,
        });
      }

      setMenuDetail(result);
    });
    Axios.get(
      `https://shelldanlabs.com:3010/api/getOutlet_menu_desc_Outlet/${JSON.stringify(
        tId
      )}`
    ).then((responce) => {
      setOutletDesc(responce.data);
    });
  }, []);
  const repeat_chilli = (no_of_times) => {
    return [...Array(no_of_times)].map((e, i) => (
      <Image
        key={i}
        style={{ width: 30, height: 15 }}
        source={require("./../assets/redchilli.png")}
      />
    ));
  };
  return (
    <View>
      <Text style={styles.heading}>Note</Text>
      <View>
        {OutletDesc.map((item, index) => (
          <Text style={{ color: "#000000", fontSize: 15 }}>
            &#8226; {item.description}
          </Text>
        ))}
      </View>
      <Text style={styles.heading}>Menu List</Text>

      <SectionList
        sections={MenuDetail}
        renderItem={({ item }) => (
          <View
            style={{
              backgroundColor: "#f7f9fa",
              borderRadius: 10,
              padding: 10,
              flexDirection: "column",
              height: 120,
              margin: 3,
              flex: 1,
              borderBottomColor: "#05aafc",
              justifyContent: "center",
            }}
          >
            <View style={styles.iconContainer}>
              <Image
                style={styles.showIcon}
                source={
                  item.is_non_veg
                    ? require("./../assets/nonveg.png")
                    : require("./../assets/veg.png")
                }
              />
              <View style={{ flexDirection: "row" }}>
                {item.spicy_level > 0 && repeat_chilli(item.spicy_level)}
              </View>
            </View>
            <View style={styles.itemContainer}>
              <Text style={{ color: "#000000", fontSize: 20 }}>
                {item.menu_name}
              </Text>
              <Text
                style={{
                  color: "#000000",
                  fontSize: 20,
                }}
              >
                {item.price}
              </Text>
            </View>

            {item.description && (
              <View>
                <Text
                  style={{
                    color: "#000000",
                    fontSize: 15,
                    fontStyle: "italic",
                  }}
                >
                  ( {item.description} )
                </Text>
              </View>
            )}
          </View>
        )}
        renderSectionHeader={({ section }) => (
          <View
            style={{
              backgroundColor: "#ffb300",
              marginVertical: 5,
            }}
          >
            <Text style={styles.sectionHeader}>
              {section.title} ( {section.data.length} )
            </Text>
          </View>
        )}
        keyExtractor={(item, index) => index}
      />
    </View>
  );
};

export default MenuListOnOutlet;

const styles = StyleSheet.create({
  heading: {
    backgroundColor: "#00A6A6",
    color: "#1E1014",
    fontWeight: "bold",
    fontSize: 20,
    textAlign: "center",
    paddingVertical: 5,
    marginTop: 5,
  },
  gridView: {
    marginTop: 10,
    flex: 1,
    alignContent: "center",
  },
  ImageStyle: {
    flex: 1,
    resizeMode: "contain",
  },
  itemContainer: {
    backgroundColor: "#f7f9fa",
    borderRadius: 10,
    padding: 5,
    flexDirection: "row",
    height: 100,
    margin: 3,
    justifyContent: "space-between",
    flex: 1,
    borderBottomColor: "#05aafc",
  },
  iconContainer: {
    backgroundColor: "#f7f9fa",
    borderRadius: 10,
    padding: 5,
    flexDirection: "row",
    height: 20,
    justifyContent: "space-between",
    flex: 1,
    borderBottomColor: "#05aafc",
  },
  itemDescriptionBottom: {
    backgroundColor: "#f7f9fa",
    borderRadius: 0,
    padding: 5,
    marginHorizontal: 3,
    flex: 1,
  },
  container: {
    flex: 1,
    paddingTop: 22,
  },
  sectionHeader: {
    paddingTop: 5,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 5,
    border: 1,
    fontSize: 18,
    fontWeight: "bold",
    textAlign: "center",
    color: "#0b1575",
  },
  item: {
    padding: 10,
    fontSize: 18,
    height: 44,
  },
  showIcon: {
    width: 20,
    height: 20,
  },
});
